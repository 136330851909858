	.tournament-calendar__container {
		max-height: calc(100vh - 80px);
		padding: 0 10px;
		font-family: Arial, sans-serif;
		background-color: var(--palette-live-bg-0-white);

		.tournament-calendar__close-icon {
			width: 100%;
			text-align: end;
			padding: 0;
		}

		.tournament-calendar__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;

			h2 {
				margin: 0;
				font-size: 20px;
				color: var(--palette-live-blue);
				margin-left: 10px;
			}

			.tournament-calendar__arrows {
				display: flex;
				align-items: center;
				justify-content: center;

				.tournament-calendar__arrow {
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					&.up {
						transform: rotate(90deg);
						padding: 0px 0px 0 10px;
					}

					&.down {
						transform: rotate(270deg);
						padding: 0px 0px 0px 10px;
					}
				}
			}
		}

		.slider {
			height: 168px;
			position: relative;
			overflow: hidden;
		}

		.tournament-calendar__days {
			display: grid;
			grid-template-columns: repeat(7, 1fr);
			grid-gap: 5px;
			margin-top: 10px;

			.day-name {
				text-align: center;
				font-size: 14px;
				color: var(--palette-live-grey-1);
				margin-bottom: 16px;
			}

			.day {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				cursor: pointer;
				font-size: 20px;
				color: var(--palette-live-grey-2);
				font-weight: bold;
				height: 35px;
				width: 35px;

				&.active {
					border-radius: 100px;
					background-color: var(--palette-live-blue);
					color: var(--palette-live-bg-0-white);
				}
			}

			.day:hover {
				background-color: var(--palette-live-blue-3);
				border-radius: 100px;
			}

			.empty-day {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				color: var(--palette-live-grey-6);
				font-weight: bold;
			}
		}

		.tournament-calendar__venues {
			padding-top: 16px;
		}

		.tournament-calendar__buttons {
			padding-top: 20px;

			button:first-child {
				margin-bottom: 10px;
			}
		}
	}