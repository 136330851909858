.password-edition {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__form {
		margin: 16px 0 0 0;

		.password-edition__button {
			margin: 0 0 16px 0;
		}
	}
}