.info {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		font-weight: var(--font-weight-regular);
		margin: 0 0 0 0;
	}
}