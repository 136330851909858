.roundrobin-grid__wrapper {
	padding: 10px 16px 20px 16px;
}

.roundrobin-grid__sheet {
	overflow: auto;
	overflow-y: hidden;
	width: 100%;
	display: flex;
}

.roundrobin-grid__sheet::-webkit-scrollbar {
	width: 0;
}

.roundrobin-grid__content {
	display: table;
	border-collapse: collapse;
	min-width: max-content;
}

.roundrobin-grid__content--sticky {
	display: table;
	border-spacing: 0;
	position: sticky;
	left: 0;
	z-index: 9;
	overflow: auto;
	overflow-y: hidden;
	white-space: nowrap;
	background-color: var(--palette-live-grey-12);
}

.roundrobin-grid__row {
	display: table-row;
	height: 50px;
	background-color: transparent;
}

.row-doubles {
	display: table-row;
	height: 64.5px;
}

.row-header {
	height: 40px;
	max-height: 40px;
	box-sizing: border-box;
}

.empty {
	display: table-row;
	height: inherit;
	width: 5px;
	min-width: 5px;
	border-top: 1px solid var(--palette-live-grey-6);
	border-bottom: 1px solid var(--palette-live-grey-6);
}

.roundrobin-grid__header {
	color: var(--palette-live-grey-0);
	font-size: var(--font-size-17);
	font-weight: 500;
	padding: 0 10px;
	display: table-cell;
	border: 1px solid var(--palette-live-grey-6);
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	height: inherit;
	position: relative;
	background-color: var(--palette-live-bg-0-white);
	box-sizing: border-box;

	&.blue {
		color: var(--palette-live-blue);
	}

	&.active {
		cursor: pointer;
	}
}

.group-name__container {
	border-top-left-radius: 15px;
	text-align: left;
	font-size: var(--font-size-22);
	font-weight: 600;
	padding: 0 15px;
	height: 40px;
	max-height: 40px;

	.group-name {
		display: flex;
		align-items: center;

		svg {
			margin-left: 10px;
		}
	}
}

.roundrobin-grid__header--inner {
	font-size: var(--font-size-14);
	padding-left: 5px;
}

.roundrobin-grid__cell {
	display: table-cell;
	border: 1px solid var(--palette-live-grey-6);
	background-color: var(--palette-live-bg-0-white);
	color: var(--palette-live-grey-2);
	font-size: var(--font-size-17);
	font-weight: 400;
	vertical-align: middle;
	min-width: 45px;
	height: inherit;
	position: relative;
	text-align: center;
}

.result-cell {
	background-color: var(--palette-live-grey-0);
	color: var(--palette-live-bg-0-white);
	border-bottom: 1px solid var(--palette-live-grey-6);
	min-width: 30px;
	padding: 0;
	box-sizing: border-box;

	&.active {
		cursor: pointer;
	}
}

.roundrobin-grid__cell-match-result {
	padding: 0 7px;
	white-space: nowrap;
}

.roundrobin-grid__cell-container {
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	width: 100%;
	height: 100%;
}

.roundrobin-grid__cell--inner {
	border-right: 1px solid var(--palette-live-grey-6);
	color: var(--palette-live-grey-2);
	font-size: var(--font-size-17);
	font-weight: 400;
	white-space: nowrap;
	min-height: 100%;
	min-width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
}

.roundrobin-grid__cell--inner:last-child {
	min-height: 40px;
	box-sizing: border-box;
	border-right: none;
}

.result-cell__score:last-child {
	color: var(--palette-live-blue-2);
}

.roundrobin-grid__cell--grey {
	background-color: var(--palette-live-grey-5);
}

.participant-name__container {
	border-top: none;
	border-left: 1px solid var(--palette-live-grey-6);
	border-right: 1px solid var(--palette-live-grey-6);
	border-bottom: 1px solid var(--palette-live-grey-6);
	position: relative;
	padding-left: 15px;
	white-space: nowrap;
	overflow: auto;
	min-width: 45px;
	// max-width: 115px;
	box-sizing: border-box;
	text-align: left;

	&::-webkit-scrollbar {
		display: none;
	}

	& {
		scrollbar-width: none;
	}
}

.roundrobin-grid__row:last-child .participant-name__container {
	border-bottom-left-radius: 15px;
}

.participant-name__order {
	position: absolute;
	top: 2px;
	left: 2px;
	color: var(--palette-live-grey-7);
	font-size: var(--font-size-14);
	font-weight: 400;
}

.roundrobin-grid__icon-ball {
	transform: scale(0.9);
	border-radius: 100%;
	position: absolute;
	top: 2px;
	left: 2px;
}

.roundrobin-grid__cell-sets {
	height: 100%;
}

.roundrobin-grid__cell-score {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-family: 'Sawarabi Gothic';
	height: 100%;
	padding: 0 5px;
	box-sizing: border-box;
}

.roundrobin-grid__cell-score p {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	margin: 0;
	padding: 0 5px;
}

.roundrobin-grid__cell-score p:first-child {
	padding-left: 10px;
}

.roundrobin-grid__cell-score p:last-child {
	padding-right: 10px;
}

.roundrobin-grid__cell-score>p>span {
	display: flex;
	justify-content: center;
	color: var(--palette-live-blue);
	white-space: nowrap;
}

.participant-name__item {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding-right: 9px;
	box-sizing: border-box;
}

.participant-name__item>span {
	padding: 0 0 5px 0;
}

.participant-name__item>span:last-child {
	padding: 0;
}

.roundrobin-grid__popup-item-container {
	width: 100%;
	border-top: 1px solid var(--palette-live-grey-6);
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		padding: 10px;
	}
}

.roundrobin-grid__popup-item-container:last-child {
	border-bottom: 1px solid var(--palette-live-grey-6);
}

.roundrobin-grid__popup-item {
	display: flex;
	align-items: center;
	padding: 15px;
	color: var(--palette-live-grey-0);

	.roundrobin-grid__popup-item-number {
		width: 30px;
		font-size: var(--font-size-14);
		font-weight: 500;
		color: var(--palette-live-grey-9);
	}

	.roundrobin-grid__popup-item-double {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.roundrobin-grid__popup-item-name {
		display: block;
		font-size: var(--font-size-17);
	}
}

.roundrobin-grid__popup-button-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 16px 0 16px;

	button {
		margin: 0;
	}

	button:first-child {
		margin: 0 5px 0 0;
	}

	button:last-child {
		margin: 0 0 0 5px;
	}
}