.keen-slider__buttons{
	display: flex;
	flex-direction: column;
	height: 60%;
	justify-content: space-between;
	align-items: center;

	.slider__button{
		cursor: pointer;
		height: 40px;
		width: 40px;
		border: none;
		background-color: transparent;

		.slider__button-icon{
			transform: scale(1.2);
		}
	}
}