.add-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 80px;
	right: 16px;
	height: 59px;
	width: 59px;
	border-radius: 100%;
	background-color: var(--palette-live-blue);
	cursor: pointer;

	&__icon {
		fill: var(--palette-live-bg-0-white);
	}
}