.loading {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto 0;

	&__rolling-icon {
		width: 30px;
		height: 30px;
		stroke: var(--palette-live-grey-6);
	}
}