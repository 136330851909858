.round-robin__nogrid {
	padding: 0 16px;
	border: 1px solid var(--palette-live-grey-6);
	box-sizing: border-box;
	border-radius: 13px;
	margin: 0 16px 16px 16px;
	background-color: var(--palette-live-bg-0-white);
	display: flex;
	align-items: center;
	max-width: 200px;
	width: 100%;

	&-group-name {
		font-weight: 500;
		font-size: var(--font-size-22);
		margin: 0 16px 0 0;
		border-right: 1px solid var(--palette-live-grey-6);
		padding: 16px 16px 16px 0;
	}
}