.community {
	overflow: auto;
	margin: 16px 16px 0 16px;
	flex: 1;

	a:last-child {
		margin: 16px 0 0 0;
	}
}

.community__popup-input {
	box-sizing: border-box;
	padding: 20px 16px 20px 16px;
	font-size: var(--font-size-18);
	width: 100%;
	background-color: var(--palette-live-grey-8);
	border: solid var(--palette-live-grey-5);
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-right-width: 0;
	border-left-width: 0;
	outline: none;
	border-radius: 0px;
}

.community__popup-buttons {
	display: flex;
	justify-content: space-between;
	width: inherit;
	margin: 20px 16px 0 16px;

	button:first-child {
		margin-right: 4px;
	}

	button:last-child {
		margin-left: 4px;
	}

	@media (max-width: 360px) {
		display: flex;
		flex-direction: column;

		button:first-child {
			margin: 0 0 8px 0;
		}

		button:last-child {
			margin-left: 0px;
		}
	}
}