.no-play-grid {
	margin: 20px 0 0 0;
	padding: 0 0 0 16px;
	margin-bottom: 16px;

	.no-play-grid__table-wrapper {
		display: flex;
		overflow: auto;
		overflow-y: hidden;
		padding-right: 15px;

		.no-play-grid__table-container {
			min-width: 100px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			border-top: 1px solid var(--palette-live-grey-6);
			border-left: 1px solid var(--palette-live-grey-6);
			border-right: 1px solid var(--palette-live-grey-6);
			background-color: var(--palette-live-bg-0-white);

			.no-play-grid__table {
				display: flex;
				flex-direction: column;
				height: 100%;

				.no-play-grid__row-header {
					border: solid var(--palette-live-grey-6);
					border-top-width: 0;
					border-right-width: 0;
					border-bottom-width: 1px;
					border-left-width: 0;
					border-top-left-radius: 15px;
					flex-shrink: 0;
					display: flex;
					align-items: center;

					.no-play-grid__header {
						text-align: center;
						margin: 0;
						padding: 0 15px;
						font-weight: 600;
						font-size: var(--font-size-18);
						height: 42px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.no-play-icon {
						width: 21px;
						fill: var(--palette-live-grey-0);
					}
				}

				.no-play-grid__row-table {
					display: flex;
					justify-content: space-between;
					align-items: stretch;

					.no-play-grid__index {
						font-size: var(--font-size-17);
						font-weight: var(--font-weight-regular);
						padding: 10px 0;
						margin: 0 0 0 5.1px;
						background-color: var(--palette-live-grey-0);
						color: var(--palette-live-bg-0-white);
						display: flex;
						align-items: center;
						justify-content: center;
						border-bottom: 1px solid var(--palette-live-grey-6);
						min-width: 30px;
					}

					// .no-play-grid__mnemonics {
					// 	padding: 10px 10px 10px 10px;
					// 	font-size: var(--font-size-17);
					// 	font-weight: var(--font-weight-regular);
					// 	margin: 0;
					// 	display: flex;
					// 	align-items: center;
					// }

					.no-play-grid__match-player-wrapper {
						color: var(--palette-live-grey-0);
						padding: 0 10px 0 10px;
						font-size: var(--font-size-17);
						font-weight: var(--font-weight-regular);
						display: flex;
						flex-direction: column;
						justify-content: center;
						flex: 1;
						box-sizing: border-box;
						border-bottom: 1px solid var(--palette-live-grey-6);
						border-right: 1px solid var(--palette-live-grey-6);
					}

					.tooltip-container {
						&:last-child {
							.no-play-grid__match-double-player-wrapper {
								margin: 2px 0 10px 0;
							}
						}
					}

					.no-play-grid__match-double-player-wrapper {
						color: var(--palette-live-grey-0);
						margin: 10px 0 2px 0;
						font-size: var(--font-size-17);
						font-weight: var(--font-weight-regular);
					}

					&:last-child {
						.no-play-grid__index {
							border-bottom: 1px solid var(--palette-live-grey-0);
						}
					}
				}
			}
		}

		.no-play-grid__table-container:first-child {
			border-top-left-radius: 15px;

			.no-play-grid__header {
				border-top-left-radius: 15px;
			}
		}

		.no-play-grid__table-container:last-child {

			.no-play-grid__header {
				border-top-right-radius: 15px;
			}
		}
	}
}