.splash-screen {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--palette-live-blue);
	flex: 1;

	&__logo-container {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.tentur-logo__icon {
			width: 187px;
			fill: var(--palette-live-bg-1-white);
		}

		.splash-screen__logo-text {
			color: var(--palette-live-bg-0-white);
			font-size: var(--font-size-21);
			margin: 16px 0 0 0;
			font-weight: var(--font-weight-regular);
			text-align: center;
		}
	}

	&__button-wrapper {
		display: flex;
		flex-direction: column;
		margin: 0 20px 0 20px;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--palette-live-bg-1-white);
		font-size: var(--font-size-12);
		font-weight: var(--font-weight-regular);
		margin: 16px 20px 16px 20px;
		cursor: pointer;
	}
}