.player-form {
	overflow: auto;
	padding: 0 16px 16px 16px;
	flex: 1;

	&__form {
		margin: 16px 0 0 0;

		.player-form__radio-buttons {
			width: 100%;
			max-width: 350px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 25px;
			margin-top: 5px;

			@media (max-width: 285px) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		.player-form__submit {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	&__delete-button {
		margin: 30px 0 20px 0;
	}
}