.markdown {
  height: 100%!important;
}
.markdown-editor {
  width: 100%;
  & label {
    color: var(--palette-live-grey-7);
    font-size: var(--font-size-18);
    left: 16px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 10px;
    transition: .3s;
    white-space: nowrap;
    margin-bottom: 20px;
  }
  .w-md-editor-text {
    min-height: 100%!important;
  }
}
button[data-name="preview"] {
  display: none;
}