	.participants__container {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-bottom: 1px solid var(--palette-live-grey-6);
		cursor: pointer;

		p {
			margin: 0;
		}

		&.single {
			padding: 13px 16px;
		}

		&.disable {
			color: var(--palette-live-grey-6);
		}

		&:last-child {
			border-bottom: none;
		}

		.participants-index {
			font-size: var(--font-size-18);
			margin: 0 10px 0 0;
			font-weight: var(--font-weight-regular);
			color: var(--palette-live-grey-0);
			min-width: 20px;
		}

		.participants-doubles {
			overflow: hidden;
			max-width: max-content;
			width: calc(100% - 1px);
		}

		.participants-name {
			font-size: var(--font-size-18);
			margin: 0 0 10px 0;
			font-weight: var(--font-weight-regular);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			&.single {
				margin: 0;
				width: calc(100% - 32px);
				max-width: max-content;
			}

			&:last-child {
				margin: 0 0 0 0;
			}
		}

		.participants-rating {
			font-size: var(--font-size-18);
			margin: 0 0 0 16px;
			font-weight: var(--font-weight-regular);
		}
	}