.nogroups {
	width: 100wv;
	box-sizing: border-box;
	margin: 20px;
	padding: 20px;
	height: 60px;
	border: 1px solid var(--palette-live-grey-6);
	border-radius: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--palette-live-bg-0-white);

	p {
		font-size: 20px;
		font-weight: 400;
		text-align: center;
	}
}