.pre-stage__container {
	padding: 10px 16px;
	background-color: var(--palette-live-grey-15);
	border-bottom: 1px solid var(--palette-live-grey-7);

	.pre-stage__participants-container {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-bottom: 1px solid var(--palette-live-grey-11);
		cursor: pointer;
		border-radius: 5px;

		&:last-child {
			border-bottom: none;
		}

		.pre-stage__participants-wrapper {
			display: flex;
			width: 100%;
			align-items: center;

			.pre-stage__participants-index {
				font-size: var(--font-size-18);
				margin: 0 20px 0 0;
				font-weight: var(--font-weight-regular);
				color: var(--palette-live-grey-0);
				min-width: 20px;
			}

				.pre-stage__players-name {
					font-size: var(--font-size-18);
					margin: 0;
					font-weight: var(--font-weight-regular);
		
					&.extra-margin-bottom{
						margin-bottom: 10px;
					}
				}

			.pre-stage__participants-rating {
				font-size: var(--font-size-18);
				margin: 0 0 0 16px;
				font-weight: var(--font-weight-regular);
			}
		}
	}
}

.pre-stage__button-wrapper {
	margin: 0 16px 20px 16px;

	.double-button.fixed {
		bottom: 140px;
	}
}

.drag-icon {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pre-stage__restore-wrapper {
	margin: 20px 16px 20px 16px;

	.pre-stage__restore-title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-16);
		margin: 0 0 0 0;
		font-weight: var(--font-weight-medium);
		background-color: var(--palette-live-bg-0-white);
		padding: 5px 5px 5px 5px;
		position: relative;
		top: 8px;
		left: 16px;
	}
}