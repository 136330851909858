.stage-form {
	padding-top: 15px;
	flex: 1;

	.double-button.fixed {
		bottom: 165px;
	}
}

.stage-form__level {
	margin: 0 16px 0 16px;

	.stage-form__level-content {
		display: flex;
		align-items: center;
		margin: 15px 0;

		.stage-form__level-name {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-bold);
		}
	}

	&:first-child {
		.stage-form__level-content {
			margin: 0 0 15px 0;
		}
	}

	.delete-icon {
		width: 24px;
		height: 24px;
		fill: var(--palette-live-grey-6);
		cursor: pointer;
	}
}

.stage-form__popup-message {
	.popup {
		&__wrapper {
			max-width: 340px;

			.popup__title {
				width: 100%;
			}
		}
	}

	.stage-form__popup-message-text {
		text-align: center;
		text-wrap: wrap;
		font-size: var(--font-size-18);
		padding: 10px 16px;
		margin: 0;
	}
}

.stage-form__buttons-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 16px 0 10px 0;
	flex-wrap: wrap;

	svg,
	span {
		cursor: pointer;
	}

	&--margin {
		margin: 16px 16px 16px 16px;
	}

	button:first-child {
		margin: 0 8px 0 0;
	}

	@media (max-width: 313px) {
		button:first-child {
			margin: 0 8px 8px 0;
		}
	}
}

.stage-form__button-bottom {
	margin: 16px 16px 16px 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	button:first-child {
		margin-right: 5px;
	}

	button:last-child {
		margin-left: 5px;
	}
}

@media (max-width: 420px) {
	.stage-form__button-bottom {
		flex-direction: column;

		button:first-child {
			margin: 0 0 8px 0;
		}

		button:last-child {
			margin: 0;
		}
	}
}