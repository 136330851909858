.stages-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 16px;
	box-sizing: border-box;

	&__wrapper {
		display: flex;
		justify-content: left;
		align-items: center;

		&.keen-slider {
			overflow: hidden;
		}

		.stages-menu__button {
			background-color: var(--palette-live-bg-0-white);
			color: var(--palette-live-grey-7);
			border: 2px solid var(--palette-live-grey-6);
			height: 44px;
			border-radius: 100px;
			width: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--font-size-22);
			font-weight: var(--font-weight-semi-bold);
			margin: 10px 8px 0 0;
			cursor: pointer;
			padding: 0;

			&.active {
				border: 2px solid var(--palette-live-blue);
				background-color: var(--palette-live-blue);
				color: var(--palette-live-bg-0-white);
			}

			&:last-child {
				margin: 10px 0 0 0;
			}
		}

		.stages-menu__item {
			background-color: var(--palette-live-bg-0-white);
			border: 2px solid var(--palette-live-grey-6);
			height: 44px;
			border-radius: 100px;
			width: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--font-size-22);
			font-weight: var(--font-weight-semi-bold);
			cursor: pointer;
			transition: all 0.3s ease;
			margin: 10px 8px 0 0;
			box-sizing: border-box;

			&.active {
				border: 2px solid var(--palette-live-grey-6);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 3px;
				width: 170px;

				.stages-menu__item-button {
					border: 1px solid var(--palette-live-blue);
					background-color: var(--palette-live-blue);
					color: var(--palette-live-bg-0-white);
					height: 34px;
					border-radius: 100px;
					width: 34px;
					padding: 0;
				}
			}

			&-button {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: var(--font-size-22);
				font-weight: var(--font-weight-semi-bold);
				color: var(--palette-live-grey-7);
				cursor: pointer;
			}

			&-icons {
				overflow: hidden;
				white-space: nowrap;
				transition: width 0.3s ease;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: absolute;
				top: 0;
				left: 50px;
				width: 0;
				height: 100%;

				.stages-menu__item-icon {
					cursor: pointer;
					fill: var(--palette-live-grey-7);
					transform: scale(1.1);
					width: 38px;

					&--active {
						transform: scale(1.1);
						cursor: pointer;
						fill: var(--palette-live-blue);
						width: 38px;
					}
				}
			}
		}

		.keen-slider__slide:last-child {
			.stages-menu__item {
				margin: 10px 0 0 0;
			}
		}
	}

	&__block {
		align-items: center;
		display: flex;
		margin: 10px 0 0 0;

		&-line {
			border-right: 2px solid var(--palette-live-grey-6);
			height: 52px;
			margin-right: 5px;
			background-color: var(--palette-live-grey-6);
		}

		&-button-add {
			align-items: center;
			background-color: var(--palette-live-grey-2);
			border-radius: 50px;
			color: var(--palette-live-bg-0-white);
			display: flex;
			font-size: 40px;
			font-weight: var(--font-weight-extra-light);
			height: 44px;
			justify-content: center;
			width: 44px;
			cursor: pointer;
			box-sizing: border-box;
			padding: 0;
		}
	}

	.stages-menu__popup-wrapper {
		margin: 0 16px 0 16px;

		.stages-menu__popup-description {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 20px 0;
			font-weight: var(--font-weight-regular);
			text-align: center;
		}
	}
}