.double-button {
	max-width: 300px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 53px;
	cursor: pointer;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
	border-radius: 100px;

	&.fixed {
		position: fixed;
		right: 16px;
		z-index: 100;
		-webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);

		.double-button--left,
		.double-button--right {
			width: unset;
		}

		@media (max-width: 343px) {
			& {
				max-width: 230px;
			}

			.double-button--left {
				font-size: var(--font-size-16);
				padding: 13px 18px 13px 18px;
			}

			.double-button--right {
				font-size: var(--font-size-16);
				padding: 13px 18px 13px 18px;
			}
		}
	}

	.double-button--left,
	.double-button--right {
		background-color: var(--palette-live-grey-0);
		height: 100%;
		box-sizing: border-box;
		color: var(--palette-live-grey-8);
		border: none;
		padding: 0;
		font-size: var(--font-size-20);
		cursor: pointer;
	}

	.double-button--left {
		width: 30%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-right: 1px solid var(--palette-live-grey-8);
		color: var(--palette-live-grey-8);
		padding: 0 35px 0 35px;
	}

	.double-button--right {
		flex: 2;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		position: relative;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background: var(--palette-live-grey-8);
			opacity: 0.3;
			left: 0;
			bottom: 0;
			transition: transform .5s;
			transform: translateX(-100%);
			clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
		}

		&.clicked:before {
			transform: translateX(100%);
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
	}

	.loader {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.loader-icon {
			stroke: white;
			height: 23px;
			width: 23px;
		}
	}
}