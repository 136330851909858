.back-button {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&__icon {
		width: 24px;
		height: 24px;
		margin: 0 0 0 7px;
		fill: var(--palette-live-grey-1);
		flex-shrink: 0;
	}
}