.radio-button-light {
	padding: 6px 6px 6px 16px;
	cursor: pointer;
	transition: .3s;

	&__input {
		display: none;
		cursor: pointer;

		&:checked+label:before {
			background-color: var(--palette-live-blue);
			box-shadow: inset 0px 0px 0px 2px rgb(255 255 255);
		}
	}

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		font-weight: var(--font-weight-regular);

		&:before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			border: 2px solid var(--palette-live-blue);
			border-radius: 100%;
			margin: 0 15px 0 0;
			flex-shrink: 0;
		}
	}
}