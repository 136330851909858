.category-form__container {
  padding: 0 20px 70px 20px;
}

.category-form__input {
  margin: 15px 0;
}

.category-form__input-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: var(--font-size-19);
}

.category-form__search-field {
  border-radius: 9px;
}

.category-form__error {
  color: #F20505;
}

.category-form__main-error{
  margin: 20px 0;
  color: #F20505;
  text-align: center;
}

.category-form__submit {
  margin: 20px 0;
}

.inputs-radio__separator{
  border-bottom: 1px solid #CCCCCC;
}

.category-form__inputs-radio {
  margin-top: 20px;
}

.inputs-radio__button {
  transform: scale(1.3);
}

.category-form__inputs-radio > label {
  font-size: var(--font-size-17);
}

.category-form__error {
  color: #F20505;
}