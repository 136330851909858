.tournament-structure {
	height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__main {
		overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: var(--palette-live-bg-0-white);
	}
}