.tournament-schedule__container {
	height: 100%;
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 10px;

	.tournament-schedule {
		flex: 1;
	}
}