.double-search-players {
	margin: 16px 16px 0px 16px;
	padding: 0 0 20px 0;
	border-bottom: 1px solid var(--palette-live-grey-5);
	position: sticky;
	top: 0;
	background-color: var(--palette-live-bg-0-white);

	&__input--first {
		margin: 0 0 10px 0;

		.input {
			margin: 0;
		}
	}

	.double-search-players__buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.rounded-button {
			max-width: 250px;
		}

		.double-search-players__button-sort {
			min-width: 40px;
			display: flex;
			height: 40px;
			justify-content: center;
			align-items: flex-end;
			cursor: pointer;
		}
	}
}

.double-search-players__line {
	background-color: var(--palette-live-grey-5);
	width: 100%;
	height: 1px;
	margin: 16px 0 0 0;
	display: block;
}

.double-search-players__list-item {
	align-items: center;
	border-bottom: 1px solid var(--palette-live-grey-5);
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 0 0 0 10px;
	margin: 0 16px;
	flex: 1;

	.double-search-players__player-name {
		flex: 1;
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		font-weight: var(--font-weight-regular);
		padding: 14px 10px 14px 0;
		width: calc(100vw - 210px);
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		box-sizing: border-box;
	}

	.double-search-players__player-strength {
		width: 40px;
		height: 48px;
		border-left: 1px solid var(--palette-live-grey-5);
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: var(--font-size-18);
	}

	.double-search-players__list-item-status {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		font-weight: var(--font-weight-regular);
		margin: 0 0 0 0;
	}
}