.input {
	margin: 0 0 16px 0;

	.input__wrapper {
		margin: 0 0 0 0;
		border: 1px solid var(--palette-live-grey-5);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--palette-live-bg-3);
		padding: 0 16px 0 0;

		&--error {
			border-color: var(--palette-live-red-1);
		}

		&--focus {
			background-color: var(--palette-live-bg-0-white);
			border-color: var(--palette-live-grey-1);
		}

		.input__container {
			width: 100%;
			height: 56px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.input__element {
				width: 100%;
				height: 100%;
				color: var(--palette-live-grey-1);
				font-size: var(--font-size-16);
				margin: 0 0 0 0;
				padding: 15px 0 2px 16px;
				border: 0;
				box-sizing: border-box;
				outline: none;
				border-top-left-radius: 9px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 9px;
				background-color: var(--palette-live-bg-3);

				&--focus {
					-webkit-box-shadow: 0 0 0 100px var(--palette-live-bg-0-white) inset !important;
				}
			}

			.input__label {
				position: absolute;
				pointer-events: none;
				left: 16px;
				top: unset;
				font-size: var(--font-size-18);
				white-space: nowrap;
				overflow: hidden;
				transition: .3s;
				color: var(--palette-live-grey-3);
			}

			.input__element:focus~.input__label,
			.input__element:not(:focus):valid~.input__label,
			.input__element:autofill~.input-label {
				top: 5px;
				font-size: var(--font-size-14);
			}

			.input__element:autofill ~ .input__label,
			.input__element:-webkit-autofill ~ .input__label {
				top: 5px;
				font-size: var(--font-size-14);
			}
		}

		.visibility__icon {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			margin: 0 0 0 16px;
			fill: var(--palette-live-grey-3);
			cursor: pointer;
		}

		.edit-pen__icon {
			width: 24px;
			height: 24px;
			flex-shrink: 0;
			margin: 0 0 0 16px;
			fill: var(--palette-live-grey-0);
			cursor: pointer;
		}
	}

	.input__error {
		color: var(--palette-live-red-1);
		font-size: var(--font-size-14);
		margin: 10px 0 0 0;
	}
}