.single-players-search {
	padding: 16px;

	.single-players-search__input-wrapper {
		padding: 0 0 16px 0;
		border-bottom: 1px solid var(--palette-live-grey-7);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.single-players-search__sort {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		width: 40px;
		height: 36px;
	}

	.single-players-search__line {
		background-color: var(--palette-live-grey-7);
		width: 100%;
		height: 1px;
		margin: 16px 0 0 0;
		display: block;
	}

	.single-players-search__list-item {
		align-items: center;
		border-bottom: 1px solid var(--palette-live-grey-5);
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		flex: 1;

		.single-players-search__player-name {
			flex: 1;
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			font-weight: var(--font-weight-regular);
			padding: 14px 10px 14px 0;
			width: calc(100vw - 210px);
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			box-sizing: border-box;
		}

		.single-players-search__player-strength {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--font-size-18);
			margin: 0;
			height: 48px;
			width: 40px;
			border-left: 1px solid var(--palette-live-grey-5);
		}
			align-items: center;
		.single-players-search__list-item-status {
			color: var(--palette-live-blue);
			font-size: var(--font-size-15);
			font-weight: var(--font-weight-regular);
			margin: 0 0 0 0;
		}
	}
}