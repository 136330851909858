.missing {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__wrapper {
		flex: 1;
		display: flex;
		align-items: center;

		.missing__title {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-20);
			font-weight: var(--font-weight-medium);
			margin: 0 0 0 0;
		}
	}

	&__button {
		width: 100%;
		margin: 0 0 16px 0;
	}
}