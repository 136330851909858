.stage-generation {
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&--background {
		background-color: var(--palette-live-grey-11);
	}

	&__main {
		overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;

		.stage-generation__wrapper {
			padding-top: 15px;
			background-color: var(--palette-live-bg-1-white);
			flex: 1;
			position: relative;
			display: flex;
			flex-direction: column;

			.stage-generation__container {
				flex: 1 1;

				.stage-generation__header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 16px 16px 16px 16px;
					border: solid var(--palette-live-grey-7);
					border-top-width: 0;
					border-right-width: 0;
					border-bottom-width: 1px;
					border-left-width: 0;
					margin: 0 16px 0 16px;
	
					.stage-generation__quantity {
						margin: 0 0 0 0;
						color: var(--palette-live-grey-0);
						font-size: var(--font-size-20);
						font-weight: var(--font-weight-medium);
					}
	
					.stage-generation__stage-number {
						margin: 0 0 0 0;
						color: var(--palette-live-grey-0);
						font-size: var(--font-size-22);
						font-weight: var(--font-weight-semi-bold);
						display: flex;
						align-items: center;
					}
	
					.arrow-down__icon {
						width: 20px;
						height: 20px;
						flex-shrink: 0;
						fill: var(--palette-live-grey-0);
					}
				}
	
				.stage-generation__list-participants {
					margin: 0 16px 0 16px;
	
					&-container {
						display: flex;
						align-items: center;
	
						.stage-generation__list-participants-index {
							font-size: var(--font-size-18);
							margin: 0 20px 0 0;
							font-weight: var(--font-weight-regular);
						}
		
						.stage-generation__list-participants-name {
							font-size: var(--font-size-18);
							margin: 0 0 10px 0;
							font-weight: var(--font-weight-regular);

							&:last-child {
								margin: 0 0 0 0;
							}
						}
					}
				}

				.stage-generation__radio-wrapper {
					display: flex;
					justify-content: space-between;
					border: solid var(--palette-live-grey-7);
					border-top-width: 0;
					border-right-width: 0;
					border-bottom-width: 1px;
					border-left-width: 0;
					margin: 0 16px 0 16px;
					padding: 16px 0 16px 0;

					.stage-generation__radio-element {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: row-reverse;
						padding: 0 16px 0 16px;
						flex: 1;

						&:first-child {
							border: solid var(--palette-live-grey-7);
							border-top-width: 0;
							border-right-width: 1px;
							border-bottom-width: 0;
							border-left-width: 0;
						}

						input[type=radio] {
							display: none;
							cursor: pointer;
						}

						label {
							display: flex;
							align-items: center;
						}

						label:before {
							content: "";
							display: inline-block;
							width: 20px;
							height: 20px;
							border: 2px solid var(--palette-live-blue);
							border-radius: 100%;
							margin: 0 15px 0 0;
							flex-shrink: 0;
						}

						input[type=radio]:checked+label:before {
							background-color: var(--palette-live-blue);
							box-shadow: inset 0px 0px 0px 2px rgb(255 255 255);
						}

						.stage-generation__radio-lable {
							color: var(--palette-live-grey-0);
							font-size: var(--font-size-18);
							display: block;
							font-weight: var(--font-weight-bold);
							display: flex;
							cursor: pointer;
						}
					}
				}

				.stage-generation__counter-groupe {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 20px 16px 20px 16px;

					.counter-groupe__name {
						color: var(--palette-live-grey-0);
						font-size: var(--font-size-18);
						margin: 0 0 0 0;
						font-weight: var(--font-weight-bold);
					}

					.counter-groupe__container {
						display: flex;
						border: 1px solid var(--palette-live-grey-7);
						padding: 13px 22px 13px 22px;
						border-radius: 100px;
						align-items: center;

						.counter-groupe__counter-button {
							padding: 0 0 0 0;
							margin: 0 0 0 0;
							border: 0;
							border-radius: 0;
							background-color: var(--palette-live-bg-0-white);
							cursor: pointer;
							display: flex;

							.remove-icon {
								width: 24px;
								height: 24px;
								fill: var(--palette-live-grey-7);
								flex-shrink: 0;
							}

							.plus-white__icon {
								width: 19px;
								height: 19px;
								fill: var(--palette-live-grey-7);
								flex-shrink: 0;
							}
						}

						.counter-groupe__counter {
							margin: 0 30px 0 30px;
							color: var(--palette-live-grey-0);
							font-size: var(--font-size-20);
							font-weight: var(--font-weight-medium);
						}
					}
				}

				@media (max-width: 330px) {
					.stage-generation__counter-groupe{
						flex-direction: column;
						align-items: center;

						.counter-groupe__name{
							margin-bottom: 15px;
						}
					}

					.stage-generation__radio-wrapper{
						flex-direction: column;
						align-items: flex-start;
						justify-content: space-between;

						.stage-generation__radio-element:first-child{
							margin-bottom: 15px;
							border-right-width: 0px;
						}
					}
				}
			}

			.stage-generation__warning-text {
				margin: 15px;
				text-align: center;
				font-size: 17px;
				color: var(--palette-live-grey-1);
				// border: 1px solid var(--palette-live-grey-4);
				background-color: var(--palette-live-grey-12);
				border-radius: 11px;
				padding: 20px 15px;
			}

			.stage-generation__button-wrapper {
				margin: 0 16px 0 16px;
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-bottom: 20px;
			}
		}
	}
}