.participants__options {
	padding: 0 15px 15px 15px;

	.participants__options-container {
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.participants__options-field {
		display: block;
		color: var(--palette-live-grey-7);
	}

	.participants__options-value {
		display: block;
		margin-bottom: 6px;
	}
	
	p:first-child {
		margin: 0 0 15px 0;
		font-size: 18px;
	}

	button:last-child {
		width: 100%;
		margin: 20px 0 0 0;
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		color: var(--palette-live-grey-7);
		cursor: pointer;
	}

	&-text {
		padding: 20px 30px;
		background-color: var(--palette-live-grey-12);
		width: 100%;
		box-sizing: border-box;
		text-align: center;
		border-radius: 11px;
	}
}