.error-link__container {
	margin: 30px 20px 0 20px;
	background-color: var(--palette-live-bg-0-white);
	padding: 16px;
	border: 1px solid var(--palette-live-grey-5);
	border-radius: 16px;
	display: flex;

	.error-link__block {
		width: 47px;
		height: 47px;
		background-color: #EE856A;
		border: 1px solid #CC684D;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		margin: 0 16px 0 0;

		.warning-icon {
			width: 23px;
			height: 23px;
			fill: var(--palette-live-bg-0-white);
			margin: 0 0 3px 0;
		}
	}

	.error-link__specification {


		.error-link__title {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 7px 0;
			font-weight: 600;
		}

		.error-link__description {
			color: var(--palette-live-grey-3);
			font-size: var(--font-size-16);
			margin: 0 0 0 0;
			font-weight: 400;
		}
	}
}