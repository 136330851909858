.tournament-participants {
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;

	&__wrapper {
		flex: 1;
		margin: 0 0 20px 0;

		.tournament-participants__button-add {
			margin: 10px 16px 0 16px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			.tournament-participants__limit-title {
				color: var(--palette-live-grey-14);
				font-size: var(--font-size-16);
				font-weight: var(--font-weight-regular);
				margin: 15px 15px 0 15px;
			}
		}
	
		.tournament-participants__alert {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-15);
			padding: 20px 16px;
			margin: 16px;
			font-weight: var(--font-weight-regular);
			background-color: var(--palette-live-grey-12);
			border-radius: 11px;
			text-align: center;
		}
	}

	.tournament-participant__popup-search-buttons {
		margin: 0 16px;

		.rounded-arrow-link-block:first-child {
			margin-bottom: 10px;
		}
	}

	.tournament-participants__button-save {
		box-sizing: border-box;
		position: fixed;
		padding: 0 15px;
		bottom: 80px;
		left: 0px;
		right: 0px;
		display: flex;
		justify-content: center;
		background-color: transparent;
		z-index: 9999;
	}

	.tournament-participants__warning {
		padding: 0 15px;

		p {
			margin: 0 0 20px 0;
		}

		&-buttons{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;

			button:first-child{
				margin-bottom: 5px;
			}
		}
	}
}