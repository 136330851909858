.tournaments-block {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	background-color: var(--palette-live-bg-0-white);
	padding: 10px;
	cursor: pointer;
	border: solid var(--pale-silver);
	border-top-width: 0;
	border-right-width: 0;
	border-bottom-width: 1px;
	border-left-width: 0;

	&__date {
		display: flex;
		flex-direction: column;
		align-items: center;

		.tournaments-date__element {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-14);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-regular);

			&:nth-of-type(2) {
				margin: 0;
				font-weight: var(--font-weight-semi-bold);
				font-size: var(--font-size-24);
				padding: 2px 0 2px 0;
			}
		}
	}

	&__data {
		flex: 1;
		padding: 0 0 0 15px;

		.tournaments-data__name {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-bold);
			box-sizing: border-box;
			overflow-wrap: break-word;
		}

		.tournaments-data__description {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-14);
			margin: 8px 0 4px 0;
			font-weight: var(--font-weight-regular);
		}

		.tournaments-data__addres {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-14);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-regular);
		}
	}

	&__bubble {
		margin: 0 10px 0 0;
		display: flex;
		align-items: center;

		.tournaments-bubble {
			width: 20px;
			height: 20px;
			background-color: var(--palette-live-light-green);
			border-radius: 100px;
			box-shadow: inset -1px -3px 5px 0 rgba(140, 189, 0, 0.8), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.25);
		}
	}

	.tournaments-block__icon {
		display: flex;
		align-items: center;

		.arrow-back__icon {
			width: 24px;
			height: 24px;
			flex-shrink: 0;
			transform: rotate(180deg);
		}
	}
}