.header-tournament {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 20px 0px 20px;
	border-bottom: 8px solid var(--palette-live-grey-5);

	&__title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-22);
		margin: 0 0 0 0;
		font-weight: var(--font-weight-semi-bold);
	}

	&__wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header-tournament__container {


			.header-tournament__date {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-16);
				margin: 0 0 0 0;
				font-weight: var(--font-weight-regular);
			}

			.header-tournament__categories {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-16);
				margin: 5px 0 0 0;
				font-weight: var(--font-weight-regular);
			}
		}

		.header-tournament__copy-wrapper {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.header-tournament__copy-button {
				background-color: transparent;
				border: 0;
				margin: 0 0 0 0;
				display: flex;
				border-radius: 0;
				align-items: center;
				justify-content: flex-end;
				padding: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
	
				.chain-icon {
					fill: var(--palette-live-grey-7);
					cursor: pointer;
				}
			}
		}
	}
}