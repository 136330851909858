.bottom-menu {
	background-color: var(--palette-live-bg-0-white);
	padding: 12px 16px 12px 16px;
	display: flex;
	justify-content: space-between;
	position: relative;
	border: solid var(--palette-live-grey-5);
	border-top-width: 1px;
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;

	&__button {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		background-color: var(--palette-live-bg-0-white);
		border: 0;
		padding: 0;
		margin: 0;

		.tennis-icon {
			width: 16px;
			height: 21px;
			fill: var(--palette-live-grey-6);
		}

		.users-icon {
			width: 21px;
			height: 21px;
			fill: var(--palette-live-grey-6);
		}

		.communities-icon {
			width: 21px;
			height: 21px;
			fill: var(--palette-live-grey-6);
		}

		.account-icon {
			width: 20px;
			height: 20px;
			fill: var(--palette-live-grey-6);
		}

		.block-name {
			font-size: var(--font-size-11);
			margin: 3px 0 0 0;
			color: var(--palette-live-grey-6);
			font-weight: var(--font-weight-regular);
		}

		.change-color-icon {
			fill: var(--palette-live-blue);
		}

		.change-color-name {
			color: var(--palette-live-blue);
		}
	}
}