.account {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__link-wrapper {
		margin: 16px 0 40px 0;
	}

	&__logout-wrapper {
		margin: 0 0 16px 0;
	}
}