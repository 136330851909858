.remove-stage-button {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0 0 20px 0;
}

.remove-stage-button__delete-button {
	color: var(--palette-live-grey-7);
	font-size: var(--font-size-20);
	padding: 0;
	margin: 0;
	border: 0;
	font-weight: var(--font-weight-medium);
	background-color: transparent;
	cursor: pointer;
}

.remove-stage-button__confirmation {
	color: var(--palette-live-grey-0);
	font-size: 15px;
	text-align: center;
	font-weight: var(--font-weight-regular);
}

.remove-stage-button__confirmation-buttons {
	margin: 0 16px 0 16px;
	display: flex;
	justify-content: space-between;

	button:first-child{
		margin-right: 5px;
	}

	button:last-child{
		margin-left: 5px;
	}
}