.tournament-participants__list-wrapper {
	margin: 20px 16px 0 16px;

	.tournament-participants__title {
		display: flex;
		align-items: center;
		font-size: var(--font-size-18);
		color: var(--palette-live-grey-0);
		font-weight: var(--font-weight-semi-bold);
		padding-left: 16px;
		height: 48px;
		border-bottom: 1px solid var(--palette-live-grey-7);

		.tournament-participants__count {
			margin: 0 10px 0 0;
		}

		.tournament-participants__count {
			display: flex;
			align-items: center;

			.tournament-participants__separator {
				display: inline-block;
				width: 1.5px;
				height: 20px;
				background-color: var(--palette-live-grey-6);
				margin: 0 6px;
			}
		
			.tournament-participants__count-max {
				color: var(--palette-live-grey-7);
				position: relative;
			}
		}

		.tournament-participants__icons {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.tournament-participants__icon {
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				cursor: pointer;

				&.disable {
					cursor: default;
				}
			}
		}
	}

	.tournament-participants-list {
		.tournament-participants__item {
			align-items: center;
			border-bottom: 1px solid var(--palette-live-grey-6);
			display: flex;

			.tournament-participants__number {
				align-items: center;
				display: flex;
				flex-direction: column;
				font-size: var(--font-size-18);
				color: var(--palette-live-grey-0);
				font-weight: var(--font-weight-regular);
				justify-content: center;
				align-items: center;
				margin: 0 0 0 0;
				height: 48px;
				width: 40px;
				cursor: pointer;

				&.doubles {
					height: 66px;
					border-right: 1px solid var(--palette-live-grey-5);
				}

				.tournament_participants__edit-man {
					margin-top: 4px;
				}
			}

			.tournament-participants__name {
				font-size: var(--font-size-18);
				font-weight: var(--font-weight-regular);
				color: var(--palette-live-grey-0);
				margin: 0 0 0 0;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 48px;
				box-sizing: border-box;

				&.display-name {
					height: 65.5px;
				}

				p {
					width: calc(100vw - 180px);
					margin: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.tournament-participants__display-name {
					margin-bottom: 2px;
					font-size: var(--font-size-16);
					color: var(--palette-live-grey-7);
				}
			}

			.tournament_participants__block {
				height: 48px;
				width: 80px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-left: 10px;

				.tournament_participants__strength {
					min-width: 40px;
					max-width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0;
					font-size: var(--font-size-18);
					height: 100%;
				}

				.tournament_participants__dnd {
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						width: 20px;
						fill: var(--palette-live-grey-6);
					}
				}
			}

			.tournament-participants__names {
				flex: 1;
				height: 66px;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				flex-direction: column;
				padding-left: 10px;

				.tournament-participants__palyer--first {
					font-size: var(--font-size-18);
					font-weight: var(--font-weight-regular);
					color: var(--palette-live-grey-0);
					margin: 0 0 2px 0;
					width: calc(100vw - 170px);
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.tournament-participants__palyer--second {
					font-size: var(--font-size-18);
					font-weight: var(--font-weight-regular);
					color: var(--palette-live-grey-0);
					margin: 0 0 0 0;
					width: calc(100vw - 170px);
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.tournament-participants__palyers-strength {
				width: 40px;
				height: 66px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
				padding: 11.5px 0;
				font-size: var(--font-size-18);
				margin-left: 10px;
			}

			.tournament_participants__dnd--doubles {
				width: 40px;
				display: flex;
				justify-content: center;
				height: 66px;
				align-items: center;
				box-sizing: border-box;

				svg {
					width: 20px;
				}
			}
		}
	}
}