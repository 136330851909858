.olympicplus-grid {
	margin-bottom: 20px;
	padding: 0 0 0 16px;

	&__title {
		font-size: var(--font-size-20);
		font-weight: 500;
		margin: 10px 0;
	}

	&__table-wrapper {
		margin-top: 10px;
		overflow: auto;
		overflow-y: hidden;
		display: flex;
		padding-right: 15px;

		.olympicplus-grid__table-container {
			box-sizing: border-box;
			border-left: 1px solid var(--palette-live-grey-6);
			border-bottom: 1px solid var(--palette-live-grey-6);
			margin-right: 5.1px;

			.olympicplus-grid__table {
				display: table;
				width: max-content;
				border-right: 1px solid var(--palette-live-grey-6);
				box-sizing: border-box;
				height: 100%;

				.olympicplus-grid__row-header {
					display: table-row;
					height: 40px;

					.olympicplus-grid__header {
						display: table-cell;
						text-align: center;
						vertical-align: middle;
						box-sizing: border-box;
						margin: 0;
						font-size: var(--font-size-14);
						background-color: var(--palette-live-bg-0-white);
						overflow: hidden;

						&-container {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
							box-sizing: border-box;

							.olympicplus-grid__header-name {
								display: flex;
								align-items: center;
								height: 40px;
								font-size: var(--font-size-21);
								font-weight: 600;
								border-top: 1px solid var(--palette-live-grey-6);
								padding: 0 15px 0 15px;
								box-sizing: border-box;
								border-top-left-radius: 15px;

								svg {
									margin-left: 10px;
								}
							}

							.olympicplus-grid__header-order {
								border-top: 1px solid var(--palette-live-grey-6);
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								height: 40px;
								box-sizing: border-box;
								font-weight: 400;
							}
						}
					}
				}

				.olympicplus-grid__row {
					display: table-row;

					.olympicplus-grid__cell {
						display: table-cell;
						text-align: center;
						vertical-align: middle;
						border-top: 1px solid var(--palette-live-grey-6);
						color: var(--palette-live-grey-0);
						font-size: var(--font-size-18);
						font-weight: 400;

						.olympicplus-grid__match {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							height: 100%;
							flex-direction: row;

							&-container {
								flex-grow: 1;
								display: flex;
								align-items: center;
								justify-content: space-between;
								height: 100%;
								width: 100%;

								.olympicplus-grid__match-order {
									display: flex;
									flex-direction: column;
									align-items: center;
									justify-content: center;
									height: 100%;
									min-width: 22px;
									max-width: 40px;
									margin: 0 5px 0 0;
									border-right: 1px solid var(--palette-live-grey-6);

									.olympicplus-grid__match-number {
										margin: 0;
										font-size: var(--font-size-12);
										color: var(--palette-live-grey-7);
									}

									.olympicplus-grid__match-ball {
										transform: scale(1);
										margin-top: 3px;
									}
								}

								.olympicplus-grid__match-sides {
									text-align: left;
									width: 100%;

									.tooltip-container {
										display: block;
									}

									.olympicplus-grid__match-participant,
									.olympicplus-grid__match-dobles {
										margin: 0;
										text-align: left;
										font-size: 17px;
									}

									.olympicplus-grid__match-participant {
										height: 20px;
										display: flex;
										align-items: center;
									}

									.olympicplus-grid__match-dobles {
										margin-bottom: 10px;
										height: 45px;
										display: flex;
										flex-direction: column;
										align-items: flex-start;
										justify-content: center;
									}

									.olympicplus-grid__match-dobles:last-child {
										margin-bottom: 0;
									}

									.olympicplus-grid__match-double-player {
										margin: 0 0 5px 0;
									}

									.olympicplus-grid__match-double-player:last-child {
										margin: 0;
									}

									.olympicplus-grid__match-notplaying {
										margin: 0;
									}

									.tooltip-container:first-child {
										.olympicplus-grid__match-participant {
											margin-bottom: 7px;
										}
									}

									.empty-player {
										display: block;
										box-sizing: border-box;
										width: 100%;
										max-width: 52px;
										margin: 0;
										padding: 0;
										border-bottom: 2px solid var(--palette-live-grey-9);
									}

									.olympicplus-grid__match-loser {
										color: var(--palette-live-grey-9);
									}
								}

								.olympicplus-grid__match-sets {
									flex: 1;
									display: flex;
									justify-content: flex-start;
									height: 100%;
									cursor: pointer;
									padding: 0 6px 0 10px;

									.olympicplus-grid__match-set {
										display: grid;
										grid-template-rows: 1fr 1fr;
										width: max-content;
										margin-right: 10px;

										&-points {
											display: flex;
											align-items: center;

											.olympicplus-grid__match-set-score {
												color: var(--palette-live-blue);
												font-family: 'Sawarabi Gothic';
												margin: 0;
												font-size: 17px;
											}

											.olympicplus-grid__match-set-tiebreak {
												color: var(--palette-live-blue);
												font-family: 'Sawarabi Gothic';
												margin: 0;
												font-size: var(--font-size-11);
											}
										}
									}
								}
							}
						}
					}

					.olympicplus-grid__result-container {
						padding: 0;
						min-width: 60px;
						font-size: 17px;

						.olympicplus-grid__result {
							display: flex;
							width: 100%;
							justify-content: space-between;
							height: 100%;

							&-order,
							&-double {
								margin: 0;
								font-size: var(--font-size-17);
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: flex-start;
							}

							&-order {
								width: 30px;
								background-color: var(--palette-live-grey-0);
								color: var(--palette-live-bg-0-white);
								align-items: center;
								height: 100%;
							}

							&-name {
								min-width: 60px;
								display: flex;
								align-items: center;
								margin: 0;
								height: 100%;
								flex: 1;
								padding: 0 10px;
							}
						}
					}
				}

				&:last-child {
					.olympicplus-grid__row:last-child {
						.olympicplus-grid__result-order {
							border-bottom: 1px solid var(--palette-live-grey-0);
							box-sizing: border-box;
						}
					}
				}
			}

			&:first-child {
				border-top-left-radius: 15px;

				.olympicplus-grid__table,
				.olympicplus-grid__header {
					border-top-left-radius: 15px;
					border-top: none;
				}

				.olympicplus-grid__header {
					min-width: 180px;
				}
			}

			&:last-child {
				border-top-right-radius: 15px;
				border-right: 1px solid var(--palette-live-grey-6);
				padding: 0;
				border-bottom: none;

				.olympicplus-grid__table,
				.olympicplus-grid__header {
					border-top-right-radius: 15px;
				}

				.olympicplus-grid__table
				.olympicplus-grid__row-header
				.olympicplus-grid__header-container
				.olympicplus-grid__header-order {
					border-top-right-radius: 15px;
					font-weight: 600;
				}

				.olympicplus-grid__cell,
				.olympicplus-grid__table {
					border-right: none;
				}

				.olympicplus-grid__table {
					.olympicplus-grid__row:last-child {
						.olympicplus-grid__result-container .olympicplus-grid__result-name {
							border-bottom: 1px solid var(--palette-live-grey-6);
							box-sizing: border-box;
						}
					}
				}
			}
		}
	}
}