.plug {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;

	.icon {
		fill: var(--palette-live-grey-6);
	}

	&-title {
		text-align: center;
		margin: 10px 16px 16px 16px;
		font-size: var(--font-size-22);
		font-weight: var(--font-weight-semi-bold);
		color: var(--palette-live-grey-0);
	}

	&-description {
		text-align: center;
		font-size: var(--font-size-16);
		font-weight: var(--font-weight-regular);
		margin: 0 16px 30px 16px;
		color: var(--palette-live-grey-0);
		max-width: 200px;
	}
}