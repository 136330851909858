.match__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 10px 15px;
  background-color: #fff;
  box-sizing: border-box;
  overflow-y: auto;

  p {
    margin: 0;
    padding: 0;
  }

  .match__container-top{
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .match__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;

    .match__participants {
      width: 100%;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid var(--palette-live-grey-6);

      .match__set-participant--first,
      .match__set-participant--second {
        font-size: var(--font-size-17);
        text-align: center;
        width: 50%;
        max-width: 264px;
        padding: 2px 0;
        margin: 0 0 5px 0;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        .match__set-participant-double p:first-child{
          margin: 0 0 5px 0;
        }
      }

      .match__set-participant--second {
        border-left: 1px solid var(--palette-live-grey-6);
      }
    }

    .match__score-modal{
      padding: 0 15px;
    }

    .match__popup-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .match__popup-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &:last-child {
          margin-left: 5px;
        }

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .match__sets {
      border-bottom: 1px solid var(--palette-live-grey-6);
      max-width: 300px;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 18px 0;
      min-height: 70px;

      .match__set-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 7px;
        font-weight: 500;

        .match__set-nuber-container {
          grid-column: 1;
          align-self: center;
          justify-content: flex-end;
          color: var(--palette-live-grey-7);
          text-align: right;
          width: 100%;

          .match__set-nuber{
            padding: 0 10px;
            font-size: var(--font-size-23);
            font-weight: 400;
          }
        }

        .match__set-button-deletion {
          border: none;
          background-color: var(--palette-live-bg-0-white);
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
          box-sizing: border-box;

          button {
            border: none;
            background-color: var(--palette-live-bg-0-white);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 30px;
          }
        }

        .match__set-button-deletion {
          grid-column: 3;
          align-self: center;
          justify-content: flex-start;

          svg {
            fill: var(--palette-live-grey-3);
          }
        }

        .match__set-container,
        .match__set-container--initial {
          grid-column: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-self: center;
          font-family: "Sawarabi Gothic";
          cursor: pointer;
          width: 175px;
          height: 41px;
          box-sizing: border-box;
        }

        .match__set-score,
        .match__set-score--inactive {
          font-size: var(--font-size-23);
          color: var(--palette-live-blue);
          display: flex;
          justify-content: center;
          align-items: flex-start;
          min-width: 42px;
          margin-right: 10px;

          .match__set-points{
            margin-right: 3px;
            height: max-content;
          }

          .match__set-tiebreak,
          .match__set-tiebreak--inactive {
            margin: 01px 0 0 0;
            font-size: var(--font-size-16);
            color: var(--palette-live-blue);
          }

          .match__set-tiebreak--inactive {
            color: var(--palette-live-grey-0);
          }
        }

        .match__set-score--inactive {
          color: var(--palette-live-grey-0);
        }

        .match__set-score:last-child,
        .match__set-score--inactive:last-child {
          margin-left: 10px;
          margin-right: 0;
        }

        .match__set-container--initial {
          border: 1px solid var(--palette-live-grey-3);
          border-radius: 50px;
        }
      }
    }

    .match__sets-button {
      border: none;
      background-color: transparent;
      color: var(--palette-live-grey-7);
      font-size: var(--font-size-18);
      width: 100%;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;
    }

    .match__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 300px;

      & > button:last-child{
        margin-top: 10px;
      }

      .match__button-tiebreak {
        font-size: var(--font-size-18);
        border: 1px solid var(--palette-live-blue);
        width: 175px;
        height: 41px;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }

  .match__sliders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Sawarabi Gothic";
    margin-bottom: 5px;
    max-width: 280px;
    width: 100%;
    height: 172px;

    .sliders_container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      height: 172px;
      cursor: grab;
    }

    .sliders__separator {
      height: 155px;
      width: 1px;
      background-color: var(--palette-live-grey-6);
    }

    .slider {
      height: 168px;
      position: relative;
      overflow: hidden;
      width: 70px;
    }
  }
}

.match__popup-warning {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0 0 40px 0;
    font-size: 18px;
  }
}