.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	overflow: auto;

	&__back-wrapper {
		width: 100%;
		box-sizing: border-box;
		padding: 20px 20px 0 20px;
	}

	&__logo-wrapper {
		display: flex;
		justify-content: center;

		.tentur-logo__icon {
			width: 49px;
			height: 49px;
			fill: var(--palette-live-blue);
		}
	}

	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 40px 20px 20px 20px;
		box-sizing: border-box;

		&-title {
			color: var(--palette-live-grey-1);
			font-size: var(--font-size-32);
			margin: 0 0 4px 0;
			padding: 0 0 0 0;
			font-weight: var(--font-weight-bold);
		}

		&-description {
			color: var(--palette-live-grey-3);
			font-size: var(--font-size-18);
			font-weight: var(--font-weight-regular);
			margin: 0 0 30px 0;
		}

		.login__reset-password {
			color: var(--palette-live-blue);
			font-size: var(--font-size-18);
			font-weight: var(--font-weight-regular);
			margin: 15px 0 0 0;
			text-decoration: none;
			text-align: right;
		}
	}
}