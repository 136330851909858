.rounded-arrow-link-block {
	width: 100%;
	padding: 13px 16px;
	font-size: var(--font-size-18);
	color: var(--palette-live-grey-7);
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid var(--palette-live-grey-7);
	border-radius: 50px;
	cursor: pointer;

	p {
		margin: 0;
	}

	.rounded-arrow-link-block__arrow {
		fill: var(--palette-live-grey-7);
	}
}