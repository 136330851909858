.tournament-menu {
	background-color: transparent;
	position: relative;
	border-top: 2px solid var(--palette-live-grey-2);

	&.stages {
		border-top: none;
	}

	.tournament-menu__arrow {
		position: absolute;
		top: -15px;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		background-color: transparent;
		margin: 0 20px 5px auto;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 2px solid var(--palette-live-grey-2);
	}

	.tournament-menu__footer {
		display: flex;
		justify-content: space-between;
		padding: 10px 16px;

		&--open {
			display: flex;
			justify-content: space-between;
			padding: 10px 16px 10px 16px;
		}
	}

	.tournament-menu__link {
		cursor: pointer;
	}
}