.input-light {
	width: 100%;
	height: 56px;
	box-sizing: border-box;
	margin-bottom: 0;
	transition: margin-bottom 0.3s ease;
	color: var(--palette-live-grey-0);

	&.error {
		margin-bottom: 20px;
	}

	.input__wrapper {
		margin: 0 0 0 0;
		border-bottom: 1px solid var(--palette-live-grey-6);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px 0 0;
		height: 100%;

		&--error {
			border-color: var(--palette-live-red-1);
			position: relative;
		}

		&--focus {
			border-color: var(--palette-live-grey-1);
		}

		.input__container {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			.input__element {
				width: 100%;
				color: var(--palette-live-grey-1);
				font-size: var(--font-size-18);
				margin: 0 0 5px 0;
				padding: 0 0 0 16px;
				border: 0;
				box-sizing: border-box;
				outline: none;
				background-color: transparent;

				&--focus {
					-webkit-box-shadow: 0 0 0 100px var(--palette-live-bg-0-white) inset !important;
				}
			}

			.input__label {
				position: absolute;
				pointer-events: none;
				font-size: 14px;
				left: 16px;
				bottom: 10px;
				font-size: var(--font-size-18);
				white-space: nowrap;
				overflow: hidden;
				transition: .3s;
				color: var(--palette-live-grey-7);
			}

			.input__element:focus~.input__label,
			.input__element:not(:focus):valid~.input__label,
			.input__element:autofill~.input-label {
				top: 9px;
				font-size: var(--font-size-14);
			}
		}

		.visibility__icon {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			margin: 0 0 0 16px;
			fill: var(--palette-live-grey-3);
			cursor: pointer;
		}

		.edit-pen__icon {
			width: 24px;
			height: 24px;
			flex-shrink: 0;
			margin: 0 0 0 16px;
			fill: var(--palette-live-grey-0);
			cursor: pointer;
		}
	}

	.input__error {
		color: var(--palette-live-red-1);
		font-size: var(--font-size-14);
		margin: 3px 0 0 0;
	}
}