.print-button {
	border: 0;
	cursor: pointer;
	padding: 0 0 0 0;
	margin: 0 0 0 0;

	.print-icon {
		width: 22px;
		height: 22px;
		fill: var(--palette-live-grey-7);
		margin: 0 25px 0 0;
	}
}

.tournament-grids {
	.logo-container {
		.tentur-logo__icon {
			width: 100%;
		}
	}

	.tournament-grids__print-wrapper {
		
	}
}

.tournament-grids__popup-title {
	font-size: var(--font-size-16);
	color: var(--palette-live-grey-0);
	font-weight: var(--font-weight-regular);
	margin: 0 16px 16px 16px;
}

.tournament-grids__popup-buttons {
	display: flex;
	margin: 0 16px 0 16px;
}