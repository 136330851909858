.tournaments {
	overflow: auto;
	padding: 8px 16px 0 16px;
	flex: 1;

	.tournaments__calendar-button {
		color: var(--palette-live-blue);
		font-size: 20px;
		padding: 16px 0;
		width: 100%;
		text-align: end;
		font-weight: 500;
	}
}