.slider.keen-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--palette-live-grey-0);
  height: 100%;
  overflow: visible;
  width: 100%;
  user-select: none;
}
.slider--perspective-right .slider__inner {
  perspective-origin: calc(50% + 100px) 50%;
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
}
.slider--perspective-left .slider__inner {
  perspective-origin: calc(50% - 100px) 50%;
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
}

.slider__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  transform-style: preserve-3d;
  height: 56px;
  width: 100%;
}

.slider__slides {
  height: 100%;
  position: relative;
  width: 100%;
}

.slider__shadow-top,
.slider__shadow-bottom {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  left: 0;
  height: 30%;
  width: 100%;
  position: relative;
  z-index: 5;
  margin-bottom: 7px;
}

.slider__shadow-bottom {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
	bottom: 0;
  margin-top: 5px;
  margin-bottom: 0;
}

.slider__label {
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  margin-top: 1px;
  margin-left: 5px;
}

.slider__slide {
	font-family: 'Sawarabi Gothic';
  align-items: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 400;
  height: 56px;
  width: 100%;
  position: absolute;
  justify-content: flex-end;

  span {
    padding: 0;
    margin: 0;
  }
}
