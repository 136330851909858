.label-block {
	padding: 15px 0;
	background-color: transparent;
	border: solid var(--palette-live-grey-6);
	border-top-width: 0;
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;

	&--bottom {
		border-bottom-width: 1px;
	}

	&--top {
		border-top-width: 1px;
	}

	&__label{
		display: block;
		font-size: var(--font-size-14);
		color: var(--palette-live-grey-3);
		margin: 0 0 10px 0;
		font-weight: var(--font-weight-medium);
	}

	&__content{
		display: block;
		font-size: var(--font-size-18);
		color: var(--palette-live-grey-0);
		font-weight: var(--font-weight-regular);
		font-family: 'Roboto-Flex';
		word-wrap: break-word;
	}

	pre {
		text-wrap: wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}