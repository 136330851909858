.no-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 12px 0 0 0;
	padding: 0 16px;

	button {
		cursor: pointer;
	}

	&__title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-22);
		margin: 0 0 20px 0;
		font-weight: var(--font-weight-semi-bold);
	}

	&__wrapper {
		background-color: var(--palette-live-grey-8);
		border-radius: 18px;
		padding: 20px;
		margin: 0 16px 0 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		box-sizing: border-box;

		.no-content__description {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-16);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-regular);
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			&--margin {
				margin: 0 0 35px 0;

				&:last-child {
					margin: 0 0 0 0;
				}
			}

			.no-content__description-nostage {
				display: block;
				font-size: 18px;
			}

			.structure-icon {
				margin: 20px 0 0 0;

				svg {
					height: 35px;
					width: 40px;
					overflow: visible;
					fill: var(--palette-live-grey-7);
				}
			}

			.participants-icon {
				margin: 20px 0 0 0;

				svg {
					height: 27px;
					width: 38px;
					overflow: visible;
					fill: var(--palette-live-grey-7);
				}
			}

			.no-content__structure-icons {
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					border: 1px solid var(--palette-live-grey-7);
					border-radius: 100px;
					padding: 5px;
					margin: 10px 10px;
					fill: var(--palette-live-grey-7);
					height: 26px;
					width: 26px;
				}
			}
		}
	}

	.nocontent__popup-wrapper {
		padding: 15px;

		.nocontent__popup-description{
			text-align: center;
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 20px 0;
			font-weight: var(--font-weight-regular);
		}
	}
}