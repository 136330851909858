.venus-form {
	overflow: auto;
	flex: 1;

	&__wrapper {
			margin: 16px 16px 0 16px;
		.radio-button-light {
			padding: 12px 16px;
		}

		.venues__block {
			border-bottom: 1px solid var(--palette-live-grey-5);
			margin: 16px 0 16px 0;
			box-sizing: border-box;

			.venues__block-title {
				display: inline-block;
				margin: 0 16px 10px 16px;
				color: var(--palette-live-grey-7);
				font-size: 14px;
			}
		}

		.venus-form__button {
			padding: 16px 0 16px 0;
			display: flex;
			justify-content: center;
		}
	}

	&__delete-button {
		margin: 12px 0 16px 0;
	}
}