	.venues {
		overflow: auto;
		margin: 10px 16px 0 16px;
		flex: 1;

		.arrow-link {
			padding: 12px 0;
		}

		.venues__info {
			display: flex;
			flex-direction: column;

			p {
				margin: 0;
			}

			.venues__info-container {
				display: flex;

				.venues__number {
					font-size: var(--font-size-20);
					color: var(--palette-live-grey-0);
					font-weight: var(--font-weight-regular);
					margin: 0 16px 0 0;
					height: 100%;
				}

				.venues__name {
					display: inline-block;
					font-size: var(--font-size-18);
					color: var(--palette-live-grey-0);
					font-weight: var(--font-weight-regular);
					margin: 0;
					width: calc(100vw - 100px);
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.venues__info-item {
					font-size: var(--font-size-16);
					color: var(--palette-live-grey-7);
					margin: 2px 0;

					.type {
						display: flex;
					}
				}
			}
		}
	}