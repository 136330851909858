.password-recovery {
	flex: 1;
	margin: 16px 16px 0 16px;

	.password-recovery__form {


		.password-recovery__description {
			color: var(--palette-live-grey-1);
			font-size: var(--font-size-16);
			margin: 0 0 16px 0;
			font-weight: var(--font-weight-regular);
		}

		.password-recovery__button {

		}
	}
}