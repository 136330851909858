.round-robin__icon,
.olympic-plus__icon,
.no-player__icon,
.drop-out__icon,
.olympic__icon,
.olympic-three__icon {
	width: 24px;
	height: 24px;
	fill: var(--palette-live-grey-0);
}
