.tournament-result__container {
	padding: 0 16px 20px 16px;
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.tournament-result__participants-result {
	margin-top: 10px;
}

.tournament-result__participants-list {
	padding: 15px 0;
}

.tournament-result__participants-list-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border: 1px solid var(--palette-live-grey-7);
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	padding: 11px 10px 12px 10px;
}

.open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.tournament-result__participants-list-title {
	font-size: var(--font-size-20);
	font-weight: 500;
	margin: 0;
	flex: 1;
	text-align: center;
}

.tournament-result__participants-list-container {
	border-left: 1px solid var(--palette-live-grey-7);
	border-right: 1px solid var(--palette-live-grey-7);
	border-bottom: 1px solid var(--palette-live-grey-7);
}

.tournament-result__participants-wrapper {
	display: grid;
	grid-template-columns: 40px 1fr;
	gap: 0;
	border-bottom: 1px solid #CCCCCC;
	cursor: pointer;
}

.tournament-result__participants-list-raiting-container {
	min-height: inherit;
	background-color: var(--palette-live-grey-0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.tournament-result__participants-list-raiting {
	font-size: var(--font-size-20);
	margin: 0;
	color: var(--palette-live-bg-0-white);
}

.tournament-result__participants-list-name {
	padding: 15px 10px;
	font-size: var(--font-size-20);
	margin: 0;
	width: calc(100vw - 90px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tournament-result__participants-list-name>p {
	margin: 0 0 15px 0;
	width: calc(100vw - 90px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tournament-result__participants-list-name>p:last-child {
	margin: 0 0 0 0;
}

.tournament-result__participants-wrapper:last-child {
	border-bottom: 0px;
}

.tournament-result__participants-list-result-container{
	border-left: 1px solid var(--palette-live-grey-7);
	height: 100%;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-19);
}

.tournament-result__description-container {
	margin: 30px 0 0 0;
}

.tournament-result__description-title {
	font-size: var(--font-size-20);
	font-weight: 500;
	margin: 0 0 15px 0;
}

.tournament-result__description-block {
	padding: 15px;
	border: 1px solid var(--palette-live-grey-7);
	border-radius: 9px;
}

.description-block__text {
	color: #3A3A3A;
	font-size: var(--font-size-19);
	margin: 0 0 0 0;
	font-weight: 400;
}