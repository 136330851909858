.structure-header {

	.structure-header__wrapper {
		.structure-header__container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			height: 60px;
			border-bottom: 1px solid var(--palette-live-grey-7);

			.structure-header__quantity {
				margin: 0 0 0 0;
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-22);
				font-weight: var(--font-weight-semi-bold);
			}

			.structure-header__stage-number-wrapper {
				display: flex;

				.structure-header__stage-number {
					margin: 0;
					color: var(--palette-live-grey-0);
					font-size: var(--font-size-22);
					font-weight: var(--font-weight-semi-bold);
					display: flex;
					align-items: center;
				}

				.structure-header__edit-wrapper {
					cursor: pointer;
					width: 35px;
					height: 35px;

					.edit-note__icon {
						width: 35px;
						height: 100%;
						fill: var(--palette-live-grey-6);
						margin: 0 0 0 8px;
					}
				}
			}

			.arrow-down__icon {
				width: 20px;
				height: 20px;
				flex-shrink: 0;
				fill: var(--palette-live-grey-0);
				cursor: pointer;
			}

			.arrow-back__icon {
				width: 25px;
				height: 25px;
				fill: var(--palette-live-grey-0);
				cursor: pointer;
			}

			.structure-header__pre-stage {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;

				.structure-header__pre-stage-title {
					color: var(--palette-live-grey-0);
					font-size: var(--font-size-18);
					margin: 0 0 0 0;
					font-weight: var(--font-weight-bold);
				}

				.structure-header__pre-stage-description {
					color: var(--palette-live-grey-0);
					font-size: var(--font-size-18);
					margin: 10px 0 0 0;
					font-weight: var(--font-weight-bold);
				}
			}
		}

		.structure-header__participants {
			padding: 10px 16px 10px 16px;
			background-color: var(--palette-live-grey-15);
			border: solid var(--palette-live-grey-7);
			border-top-width: 0;
			border-right-width: 0;
			border-bottom-width: 1px;
			border-left-width: 0;

			&.no-border {
				border-bottom-width: 0;
				padding: 0;
			}
		}
	}
}