.popup-error {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.29);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	z-index: 9999;

	&__wrapper {
		max-width: 400px;
		background-color: #fff;
		border-radius: 11px;
		width: 100%;
		padding: 20px 20px 20px 20px;
		margin: 0 16px 16px 16px;

		.popup-error__body {
			margin: 0 0 20px 0;
			font-size: var(--font-size-19);
			font-weight: var(--font-weight-medium);
			text-align: center;
			color: var(--palette-live-grey-0);
			line-height: 130%;

			&::-webkit-scrollbar {
				width: 0;
			}
		}
	}
}