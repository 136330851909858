.checkbox {
	margin: 0 0 20px 0;

	&__wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.checkbox-input__wrapper {
			width: moz-fit-content;
			width: fit-content;
			border: 2px solid var(--palette-live-blue);
			border-radius: 9px;
			padding: 2px;

			.checkbox-input {
				cursor: pointer;
				position: absolute;
				z-index: -1;
				opacity: 0;
				margin: 0;
				padding: 0;
				box-sizing: border-box;

				& + label::before {
					content: "";
					display: flex;
					width: 22px;
					height: 22px;
					border-radius: 5px;
					cursor: pointer;
				}

				&:checked + label::before {
					background-color: var(--palette-live-blue);
					cursor: pointer;
				}
			}
		}

		.checkbox-label {
			color: var(--palette-live-grey-3);
			font-size: var(--font-size-14);
			margin: 0 0 0 15px;
			font-weight: var(--font-weight-regular);

			.checkbox-agreement {
				color: var(--palette-live-blue);
				text-decoration: none;
				cursor: pointer;
			}
		}
	}

	.checkbox-error {
		color: var(--palette-live-red-1);
		font-size: var(--font-size-14);
		font-weight: var(--font-weight-regular);
		margin: 10px 0 0 0;
	}
}