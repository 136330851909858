.arrow-link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 16px 20px 16px;
	border: solid var(--palette-live-grey-5);
	border-top-width: 0;
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	text-decoration: none;
	margin: 0;
	color: var(--palette-live-grey-0);
	font-size: var(--font-size-18);

	.arrow-back__icon {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		transform: rotate(180deg);
	}
}