.doubles-drag-drop-list {
	display: flex;
	font-size: var(--font-size-18);
	width: 100%;

	&__order {
		display: flex;
		flex-direction: column;
		width: 36px;

		&-number {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			flex: 1;
			border-right: 1px solid var(--palette-live-grey-11);
			border-bottom: 1px solid var(--palette-live-grey-6);
			min-height: 88px;
			max-height: 88px;
			box-sizing: border-box;

			p {
				margin: 0 0 0 0;
				width: 100%;
				text-align: center;
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.doubles-drag-drop-list__player {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding-left: 16px;
			height: 44px;
			border-bottom: 1px solid var(--palette-live-grey-6);
			box-sizing: border-box;

			&-name {
				width: calc(100vw - 170px);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				margin: 0;
			}

			.doubles-drag-drop-list__block {
				display: flex;
			}

			&-strength, &-dnd {
				width: 40px;
				display: inline-block;
				height: 44px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-dnd {
				svg {
					width: 20px;
				}
			}
		}
	}
}