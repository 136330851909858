.search {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 15px 15px 0 3px;
	background-color: var(--palette-live-bg-0-white);
	cursor: pointer;

	.loupe-icon {
		width: 22px;
		height: 22px;
		margin-right: 15px;
		fill: var(--palette-live-grey-7);
	}

	&__input {
		border: none;
		font-size: var(--font-size-18);
		color: var(--palette-live-grey-1);
		width: 100%;
		outline: none;
		padding: 0;
		background-color: var(--palette-live-bg-0-white);
		cursor: pointer;

		&::placeholder {
			color: var(--palette-live-grey-7);
		}

		&::-webkit-search-cancel-button {
			display: none;
		}
	}
}