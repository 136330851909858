.settings-account {
	overflow: auto;
	margin: 0 16px 0 16px;
	flex: 1;

	&__form {
		margin: 16px 0 0 0;

		.settings-account__button {
			margin: 0 0 16px 0;
		}
	}

	&__delete-button {
		margin: 40px 0 16px 0;
	}
}