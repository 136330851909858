.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 10px 20px;
	background-color: var(--palette-live-bg-0-white);

	&__button {
		width: 24px;
		height: 24px;
	}

	&__section {
		display: flex;
		flex-direction: column;
		align-items: center;

		.header__community-name {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-22);
			margin: 0 0 4px 0;
			font-weight: var(--font-weight-semi-bold);
		}

		.header__title {
			color: var(--palette-live-grey-2);
			font-size: var(--font-size-22);
			margin: 5px 0 0 0;
			font-weight: var(--font-weight-semi-bold);
			text-align: center;
		}

		.header__subtitle {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-16);
			margin: 5px 0 0 0;
			font-weight: var(--font-weight-regular);
			text-align: center;
			text-transform: uppercase;
		}
	}

	.header__button-container {
		width: 42px;
		height: 42px;
	}
}