.rounded-button {
	width: 100%;
	max-width: 400px;
	color: var(--palette-live-blue);
	font-size: var(--font-size-20);
	padding: 12px 0;
	border: 1px solid var(--palette-live-grey-0);
	border-radius: 100px;
	font-weight: var(--font-weight-medium);
	cursor: pointer;
	pointer-events: all;
	box-sizing: border-box;

	&.shadow {
		-webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
	}

	.rounded-button__loader{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.rounded-button__loader-icon{
		height: 33px;
		width: 33px;
	}
}