.tournament-grids__container {
	overflow: auto;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 10px;

	&.filled {
		background-color: var(--palette-live-grey-12);
	}

	.tournament-grids__level-group {
		width: max-content;
	}

	.tournament-grids {
		min-height: max-content;
		flex: 1;

		.tournament-switch__container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 10px 0;
			transition: all 0.2s ease-in;

			.tournament-switch__wrapper {
				background-color: #e1e1e1;
				padding: 1px;
				border-radius: 25px;
				border: 1px solid #d9d9d9;

				.tournament-switch__button {
					cursor: pointer;
					border: 0;
					padding: 10px 25px 10px 25px;
					margin: 0 0 0 0;
					border-radius: 25px;
					color: var(--palette-live-grey-0);
					font-size: var(--font-size-16);

					&--active {
						background-color: #fff;
					}
				}
			}
		}

		&__level-name {
			color: var(--palette-live-grey-2);
			font-size: var(--font-size-21);
			font-weight: var(--font-weight-medium);
			margin: 10px 15px 10px 15px;
		}

		&__nogroups {
			width: 100wv;
			box-sizing: border-box;
			margin: 20px;
			padding: 20px;
			height: 60px;
			border: 1px solid var(--palette-live-grey-6);
			border-radius: 11px;
			display: flex;
			align-items: center;
			background-color: var(--palette-live-bg-0-white);

			p {
				font-size: 20px;
				font-weight: 400;
			}
		}
	}

	.remove-stage-button {
		margin-top: 20px;
	}
}