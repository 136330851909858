.categories__container {
  padding: 20px 20px 0 20px;
}

.categories__block {
  border-radius: 11px;
  border: 1px solid #CCCCCC;
}
  
.categories__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: solid #CCCCCC;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
}

.categories__wrapper:last-child {
  border: 0;
}

.categories__name-container {
  display: flex;
  align-items: center;
}

.categories__number-container {
  background-color: #EBF4FF;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 0;
}

.categories__number {
  color: #3A3A3A;
  font-size: var(--font-size-19);
  margin: 0;
}

.categories__name {
  color: #3A3A3A;
  font-size: var(--font-size-19);
  margin: 0;
  font-weight: 400;
}

.categories__delete-button {
  background-color: #FFEBEB;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 100%;
  cursor: pointer;
}