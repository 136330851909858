.addition-players-bylist {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0 16px;
	height: calc(100dvh - 200px);

	&__wrapper{
		width: 100%;

		.addition-players-bylist__inputs-container {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;

			.addition-players-bylist__inputs {
				display: flex;
				align-items: center;

				.input-light {
					&.error {
						max-height: unset;
						margin: 0;

						.input__wrapper--error, .input__wrapper--focus {
							min-height: 44px;
						}

						.input__wrapper--error {
							svg {
								position: absolute;
								bottom: 10px;
								right: 16px;
							}

							@media (max-width: 335px) {
								svg {
									display: none;
								}
							}
						}
					}
				}
			}

			.addition-players-bylist__input-error {
				display: inline-block;
				margin: 5px 0 0px 0;
				color: var(--palette-live-red-1);
			}
		}
	}

	.rounded-button {
		margin-top: 20px;
	}
}