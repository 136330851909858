.profile-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	padding: 23px 20px 23px 20px;
	background-color: var(--palette-live-grey-12);
	border: 1px solid var(--palette-live-grey-4);

	.profile__photo {
		width: 120px;
		height: 120px;
		border-radius: 100px;
		object-fit: cover;
	}

	.profile__photo-letters{
		width: 120px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: var(--font-size-30);
		font-weight: var(--font-weight-medium);
		border-radius: 100px;
		background-color: var(--palette-live-grey-13);
		margin: 0;
		color: var(--palette-live-grey-0);
	}

	.profile__subtitle{
		font-size: var(--font-size-20);
		font-weight: var(--font-weight-medium);
		color: var(--palette-live-grey-0);
		margin: 12px 0 0 0;
	}
}