.select {
	width: 100%;
	height: 56px;
	border-bottom: 1px solid var(--palette-live-grey-6);
	display: flex;
	justify-content: space-between;
	position: relative;
	cursor: pointer;

	&.opened {
		border-bottom: 1px solid var(--palette-live-grey-1);
	}

	.arrow-up-down {
		margin-top: 16px;
	}

	.select-label {
		position: absolute;
		pointer-events: none;
		left: 16px;
		bottom: 11px;
		font-size: var(--font-size-18);
		white-space: nowrap;
		overflow: hidden;
		transition: .3s;
		color: var(--palette-live-grey-7);
		z-index: 1;
	}

	.select-input {
		height: 36px;
		margin-top: 19px;
		padding: 0 16px;
		display: block;
		border: none;
		width: 100%;
		box-sizing: border-box;
		cursor: pointer;
		outline: none;
		font-size: var(--font-size-18);
		color: var(--palette-live-grey-0);
	}

	&.blue {
		border-bottom: none;

		.select-label {
			color: var(--palette-live-blue);
		}

		.arrow-up-down {
			svg {
				fill: var(--palette-live-blue);
			}
		}
		
		.select-input {
			padding: 0 16px 0 0;
			color: var(--palette-live-blue);
		}

		.select-label {
			left: 8px;
		}
	}
}

.select-options {
	display: flex;
	box-sizing: border-box;
	min-width: 100%;
	background-color: var(--palette-live-bg-0-white);
	flex-direction: column;
	transition: all 0.3s ease;
	overflow-y: hidden;
	height: 0;

	&.above {
		position: absolute;
		z-index: 10;
		top: 101%;
		left: 0;
	}

	&__item {
		box-sizing: border-box;
		width: 100%;
		min-height: 48px;
		display: flex;
		align-items: center;
		padding: 6px 10px;
		border-bottom: 1px solid var(--palette-live-grey-5);
		cursor: pointer;
		font-size: var(--font-size-18);
		word-wrap: break-word;
		background-color: var(--palette-live-grey-15);
		word-break: break-all;

		&:hover {
			background-color: var(--palette-live-blue-3);
		}

		&.active {
			background-color: var(--palette-live-blue-3);
		}

		&.active.blue {
			color: var(--palette-live-blue);
		}
	}

	&_item:last-child() {
		border-bottom: none;
	}
}